<template>
  <div class="todasLojas">
    <div class="banner">
      <img class="banner-detailimg1" :src="require(`@/assets/images/banner_detail1.webp`)" />
      <img class="banner-lojas" :src="require(`@/assets/images/lojas.webp`)" />
    </div>
    <img class="banner-detailimg2" :src="require(`@/assets/images/banner_detail2.webp`)" />
    <div class="lojas">
      <div class="col-12 col-md-3 loja">
            <img class="pointer" :src="require(`@/assets/images/pointer.webp`)"> 
            <div class="card bg-transparen h-100">
                <div class="card-body">
                    <h5 class="card-title">AFLITOS</h5>
                    <p class="card-text">Av. Conselheiro Rosa e Silva, 431</p>
                    <p class="card-text">Aflitos</p>
                    <p class="card-text" style="margin-bottom: 25px"><a href="https://goo.gl/maps/dS8tDLvmEYML8PUQ7?coh=178571&entry=tt">Ver no mapa</a></p>
                    <p class="card-text"><b>Fone: <a href="tel:34232526">(81) 3423-2526</a></b></p>
                    <div class="horario">
                      <p class="card_footer">Dom a Qua das 08:40 às 21:00</p>
                      <p class="card_footer">Qui a Sáb das 08:40 às 21:30</p>
                    </div>
                </div>
            </div> 
        </div>
        <div class="col-12 col-md-3 loja">
            <img class="pointer" :src="require(`@/assets/images/pointer.webp`)" > 
            <div class="card bg-transparen h-100">
                <div class="card-body">
                    <h5 class="card-title">SHOPPING RECIFE</h5>
                    <p class="card-text">Rua Padre Carapuceiro, 777</p>
                    <p class="card-text">BV04B - Boa Viagem</p>
                    <p class="card-text" style="margin-bottom: 25px"><a href="https://goo.gl/maps/Xu7rQMqp3DRhPzr37?coh=178571&entry=tt">Ver no mapa</a></p>
                    <p class="card-text"><b>Fone: <a href="tel:34668836">(81) 3466-8836</a></b></p>
                    <div class="horario">
                      <p class="card_footer">Seg a Sáb das 09:00 às 22:00</p>
                      <p class="card_footer">Dom das 12:00 às 21:00</p>
                    </div>
                </div>
            </div> 
        </div>
        <div class="col-12 col-md-3 loja">
            <img class="pointer" :src="require(`@/assets/images/pointer.webp`)"> 
            <div class="card bg-transparen h-100">
                <div class="card-body">
                    <h5 class="card-title">SHOPPING RIOMAR</h5>
                    <p class="card-text">Av. República do Líbano, 251</p>
                    <p class="card-text">Piso 02 / Loja 2142 - Pina</p>
                    <p class="card-text" style="margin-bottom: 25px"><a href="https://goo.gl/maps/sxBHaSpZf7NmgHgu8?coh=178571&entry=tt">Ver no mapa</a></p>
                    <p class="card-text"><b>Fone: <a href="tel:33270295">(81) 3327-0295</a></b></p>
                    <div class="horario">
                      <p class="card_footer">Seg a Sáb das 09:00 às 22:00</p>
                      <p class="card_footer">Dom das 12:00 às 21:00</p>
                    </div>
                </div>
            </div> 
        </div>
        <div class="col-12 col-md-3 loja">
            <img class="pointer" :src="require(`@/assets/images/pointer.webp`)"> 
            <div class="card bg-transparen h-100">
                <div class="card-body">
                    <h5 class="card-title">SHOPPING PLAZA</h5>
                    <p class="card-text">R. Dr. João Santos Filho, 255</p>
                    <p class="card-text">Piso L5 - Casa Forte</p>
                    <p class="card-text" style="margin-bottom: 25px"><a href="https://goo.gl/maps/uwtzj69B76pNSaNF9?coh=178571&entry=tt">Ver no mapa</a></p>
                    <p class="card-text"><b>Fone: <a href="tel:30403640">(81) 3040-3640</a></b></p>
                    <div class="horario">
                      <p class="card_footer">Seg a Sáb das 09:00 às 22:00</p>
                      <p class="card_footer">Dom das 12:00 às 21:00</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <img class="banner-detailimg3" :src="require(`@/assets/images/banner_detail3.webp`)" />
    <div class="to_top">
      <a href="#" role="button" class="btn btn-md"><font-awesome-icon icon="fa-solid fa-angle-up" size="3x" /></a>
    </div>
  </div>

</template>

<script>
import { useHead } from '@unhead/vue'

export default {
  name: 'Lojas',
  setup() {
    useHead({
      title: 'Lojas'
    })
  },
  methods: {
      login () {
        this.$gtag.event('login', { method: 'Google' })
      }
    }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'AutumnChant';
        src: local("Autumn Chant"),
        url(@/fonts/Autumn-Chant.otf) format("truetype");
    }
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }

    // BANNER

  .banner-detailimg1 {
    width: 18%;
    position: absolute;
  }
  .banner-lojas {
    width: 100%;
    padding: 0 40px 0 40px;
  }
  .banner-detailimg2 {
    width: 23%;
    position: absolute;
    right: 0;
    margin-right: 40px;
    display: none;
  }

    // CARDS LOJAS
  .lojas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 60px 30px 40px 30px;
  }

  .loja {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pointer {
    width: 12%;
    margin-bottom: -15px;
  }
  .card {
    margin: 0 20px 0 20px;
    background-color: transparent;
    width: 85%;
    border-radius: 10px;
    border-color: #CE842D;
    color: #77321C
  }
  .card-title {
    margin-top: 12px;
    margin-bottom: 15px;
    font-size: 18px;
    font-family: 'GoldplayAlt';
    font-weight: bold;
    font-style: normal;
  }
  .card-body {
    align-self: center;
    text-align: center;
    line-height: 1.8;
    padding-top: 8px;
  }

  .card-text {
    font-size: 14px;
    font-family: 'Lora';
    line-height: 0.3;
  }
  .horario {
    margin-top: 30px;
  }
  .card_footer{
    font-size: 14px;
    font-family: 'Lora';
    line-height: 0.4;
  }

  // banner baixo

  .banner-detailimg3 {
    display: block;
    height: 260px;
  }
  .to_top {
    display: none;
  }


  @media (max-width: 767px) {

    .banner-lojas {
      padding: 0 26px 0 26px;
    }
    .banner-detailimg2 {
      display: block;
      margin-right: 26px;
    }
    .banner-detailimg3 {
      display: none;
    }
    .lojas {
      margin-top: 20px;
    }
    .loja {
      margin-top: 15px;
    }
    .to_top {
      display: flex;
      justify-content: flex-end;
      margin-top: -60px;
    }

    .fa-angle-up {
      color: #CE842D;
    }
  }
</style>
