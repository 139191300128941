<template>
    <nav class="navbar navbar-expand-md navbar-light fixed-top">
        <div class="container-fluid justify-content-center">
            <a href="https://myapp.dalena.com.br"><img src="@/assets/images/logo.png" alt="Dalena" height="40px" id="logo_mobile"></a>
            <button class="navbar-toggler" type="button" 
                    data-bs-toggle="collapse" data-bs-target="#navbarContent"
                    aria-controls="navbarContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="line"></span> 
                <span class="line"></span> 
                <span class="line"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-center" id="navbarContent">
                <div class="navbar-nav mr-auto">
                    <router-link class="nav-item nav-link" :to="{ name: 'Home' }"><span data-bs-target="#navbarContent" data-bs-toggle="collapse">HOME</span></router-link>
                    <a class="nav-link" href="https://loja.dalena.com.br" target="_blank" rel="noopener noreferrer">DELIVERY</a>
                    <router-link class="nav-item nav-link" :to="{ name: 'Dalena' }"><span data-bs-target="#navbarContent" data-bs-toggle="collapse">DALENA</span></router-link>
                    <span class="navbar-brand brand" href="#"><img src="@/assets/images/logo.png" alt="Dalena"></span>
                    <router-link class="nav-item nav-link" :class="($route.name == 'Produtos' || $route.name == 'Produto') ? 'active':''"  :to="{ name: 'Cardapio' }"><span data-bs-target="#navbarContent" data-bs-toggle="collapse">CARDÁPIO</span></router-link>
                    <router-link class="nav-item nav-link" :to="{ name: 'Lojas' }"><span data-bs-target="#navbarContent" data-bs-toggle="collapse">LOJAS</span></router-link>
                    <router-link class="nav-item nav-link" :to="{ name: 'Contato' }"><span data-bs-target="#navbarContent" data-bs-toggle="collapse">CONTATO</span></router-link>
                </div>            
            </div>
        </div>
        </nav>
</template>

<script>
export default {
    name: 'NavBar',
    
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }
    .navbar {
        background-color:  #EDE2D1;
        height: 100px;
        width: 100%;
        font-family: 'Lora';
    }

    .navbar-nav {
        display: flex;
        align-items: center;
    }


    .navbar-brand {
        display: block;
        margin-right: 30px;
        margin-left: 30px;
    }
    .navbar-brand>img {
        height: 50px;
    }
    .container-fluid {
        margin: auto;
    }
    .nav-link {
        color: #77321C;
        font-size: 18px;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: bold;
    }
    .nav-link:hover{
        text-shadow: 0 0 8px #CE842D;
    }
    .nav-link.router-link-active,.nav-link.active {
        color: #CE842D;
        text-shadow: 0 0 8px #CE842D;
    }    

    #logo_mobile {
        display: none;
    }

    @media (max-width: 767px) {
        .navbar-collapse {
            background-color:  #EDE2D1;
        }

        .navbar {
            height: 75px;
        }

        .brand {
            display: none;
        }

        .container-fluid {
            justify-content: space-between!important ;
        }

        #logo_mobile {
            display: block;
            margin-left: 10px;
        }

        .nav-link {
            margin-left: 10px;
        }

        .navbar-toggler {
            background-color: #EDE2D1;
            border-radius: 45px;
            width: 42px;
            height: 42px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 8px 9px 8px 9px;
        }

        .navbar-toggler .line{
            width: 100%;
            float: left;
            height: 4px;
            background-color: #77321C;
        }
    }
</style>