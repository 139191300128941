import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from "vue-gtag";
import { createHead } from "@vueuse/head"

const head = createHead()

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Icons */
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'


library.add(faSquareInstagram,faFacebook,faWhatsapp,farStar,fasStar,faAngleUp,faShareNodes)

import "bootstrap/dist/js/bootstrap.js"

createApp(App).use(store).use(router).use(VueSocialSharing).use(VueGtag, {config: { id: "G-H1MWQM0WJK" }}, router).use(head).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
