import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Dalena from '@/views/Dalena.vue'
import Cardapio from '@/views/Cardapio.vue'
import Produtos from '@/views/Produtos.vue'
import Produto from '@/views/Produto.vue'
import Lojas from '@/views/Lojas.vue'
import Contato from '@/views/Contato.vue'
import Avalie from '@/views/Avalie.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/a-dalena',
    name: 'Dalena',
    component: Dalena
  },
  {
    path: '/cardapio',
    name: 'Cardapio',
    component: Cardapio
  },
  {
    path: '/cardapio/:tipo',
    name: 'Produtos',
    component: Produtos
  },
  {
    path: '/produto/:produto',
    name: 'Produto',
    component: Produto
  },
  {
    path: '/lojas',
    name: 'Lojas',
    component: Lojas
  },
  {
    path: '/contato',
    name: 'Contato',
    component: Contato
  },
  {
    path: '/avalie',
    name: 'Avalie',
    component: Avalie
  },
/*  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about"  '../views/AboutView.vue')
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
