<template>
  <div class="dalena">
    <div class="banner">
      <img class="banner-detailimg1" :src="require(`@/assets/images/banner_detail1.webp`)" />
      <img class="banner-foto" :src="require(`@/assets/images/cardapio.webp`)" />
    </div>
    <img class="banner-detailimg2" :src="require(`@/assets/images/banner_detail2.webp`)" />
    <h1 class="title2">Conheça nosso cardápio</h1>
    <div class="know-more">
      <div class="col-12 col-md-4">
            <a href="/cardapio/torta">
              <div class="card border-light bg-transparen h-100">
                  <img v-bind:src="require(`@/assets/images/maistorta.webp`)" class="card-img-top img-fluid" alt="Tortas"> 
                  <div class="card-body">
                    <h5 class="card-title">TORTAS</h5>
                  </div>
              </div>
            </a>
        </div>
        <div class="col-12 col-md-4 center_know">
          <a href="/cardapio/bolo">
            <div class="card border-light bg-transparen h-100">
                <img v-bind:src="require(`@/assets/images/maisbolo.webp`)" class="card-img-top img-fluid" alt="Bolos"> 
                <div class="card-body">
                  <h5 class="card-title">BOLOS</h5>
                </div>
            </div> 
          </a>
        </div>
    </div>
    <div class="to_top">
      <a href="#" role="button" class="btn btn-md"><font-awesome-icon icon="fa-solid fa-angle-up" size="3x" /></a>
    </div>
  </div>

</template>

<script>
import { useHead } from '@unhead/vue'

export default {
  name: 'Cardapio',
  setup() {
    useHead({
      title: 'Cardápio'
    })
  },
  methods: {
      login () {
        this.$gtag.event('login', { method: 'Google' })
      }
    }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'AutumnChant';
        src: local("Autumn Chant"),
        url(@/fonts/Autumn-Chant.otf) format("truetype");
    }
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }
  .banner-detailimg1 {
    width: 18%;
    position: absolute;
  }
  .banner-foto {
    width: 100%;
    padding: 0 40px 0 40px;
  }
  .banner-detailimg2 {
    width: 23%;
    position: absolute;
    right: 0;
    margin-right: 40px;
  }
  .title2 {
    margin: 120px 0 45px 0;
    font-family: 'Lora';
    color: #77321C;
    font-size: 32px;
    font-style: italic;
    text-align: center;
  }


  a {
    text-decoration: none;
    color: black;
  }

  .card {
    margin: 5px 20px 5px 20px;
    background-color: transparent;
    border: none;
  }
  .card-body {
    align-self: center;
    text-align: center;
    line-height: 1.8;
    color: #77321C;
  }

  .card-text {
    font-size: 14px;
    font-family: 'Lora';
    font-weight: bold;
  }

  .card-title {
    font-size: 18px;
    font-family: 'Lora';
    font-weight: bold;
  }
  .know-more {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    padding: 0 80px 10px 80px;
  }
  .know-more>div>a>.card {
    padding-top: 10px;
  }

  .center_know{
    border-left: 1px solid rgba(119, 50, 28, 0.5);
  }

  .know-more>div {
    padding: 0 50px 0 50px;
  }

  .to_top {
    display: none;
  }


  @media (max-width: 767px) {

    .banner-foto {
      padding: 0 26px 0 26px;
    }
    .banner-detailimg2 {
      margin-right: 26px;
    }
    .title2 {
      font-size: 25px;
      margin: 40px 90px 20px 90px;
    }

    .know-more {
      padding: 10px 60px 10px 60px;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .center_know {
      border: none;
    }
    .title2>h1 {
      padding: 0 60px 0 60px;
    }

    .know-more>div {
      padding: 0;
    }
    .to_top {
      display: flex;
      justify-content: flex-end;
      margin-top: -60px;
    }

    .fa-angle-up {
      color: #CE842D;
    }
  }
</style>
