<template>
    <div class="modal" tabindex="-1" id="reviewModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-body">
                    <div class="mb-3">
                        <h1 class="titulo">{{ name }}</h1>
                    </div>
                    <div class="mb-3">
                        <p class="mensagem">Conta pra gente o que você achou desse produto</p>
                    </div>
                    <div class="mb-3">
                        <div class="rating">
                            <input id="rating1" type="radio" name="rating" :value=1 v-model="review.rating">
                            <label for="rating1">1</label>
                            <input id="rating2" type="radio" name="rating" :value=2 v-model="review.rating">
                            <label for="rating2">2</label>
                            <input id="rating3" type="radio" name="rating" :value=3 v-model="review.rating">
                            <label for="rating3">3</label>
                            <input id="rating4" type="radio" name="rating" :value=4 v-model="review.rating">
                            <label for="rating4">4</label>
                            <input id="rating5" type="radio" name="rating" :value=5 v-model="review.rating">
                            <label for="rating5">5</label>
                        </div>
                        <div class="gostei">
                            <p>Não gostei</p>
                            <p>Amei</p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label for="nameReview" class="form-label">Nome</label>
                        <input type="text" class="form-control" id="nameReview" v-model.trim="review.name">
                    </div>
                    <div class="mb-4">
                        <label for="favReview" class="form-label">Essa é sua torta favorita?</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="favReview1" :value=true v-model="review.fav">
                            <label class="form-check-label" for="favReview1">
                                Sim, eu amo!!
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="favReview2" :value=false v-model="review.fav" checked>
                            <label class="form-check-label" for="favReview2">
                                Não, tenho outras preferidas
                            </label>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label for="textReview" class="form-label">Comentário (opcional)</label>
                        <textarea class="form-control" id="textReview" rows="5" aria-describedby="opcional" placeholder="Seu comentário aqui" v-model.trim="review.review"></textarea>
                    </div>
                    <div class="alert alert-success" role="alert" v-show="reviewed">
                        Avaliação adicionada!
                    </div>
                    <button type="submit" class="btn submit_button" @click="onSubmit(review)" :disabled="reviewed">Avaliar</button>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>

export default {
    name: "Review",
    props: ['id', 'name'],
    data() {
        return {
            review: {
                name: '',
                rating: 5,
                fav: false,
                review: '',
                prod_id: this.id
            },
            reviewed: false
        }
    },
    methods: {
        onSubmit(review){
            
            review.prod_id = this.id;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(review)
            };            
            fetch('https://6zk5h46ao2tkvb3emi4xosrcju0fvayv.lambda-url.us-east-2.on.aws/api/reviews/', requestOptions)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } 
                
                let self = this
                this.reviewed = true;
                function addMsg() {
                    // document.getElementById('close').click();
                    document.getElementById('close').click();
                    self.$emit('submitted', true);
                    self.$router.push('/cardapio/torta');
                }                               
                
                setTimeout(addMsg, 3000);
            })
            .catch(error => {
                console.error('An error ocurred: ', error);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-SemiBold.ttf) format("truetype");
    }

    .btn-close {
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
    }
    .mensagem,.form-label,.form-check-label {
        font-family: 'GoldplayAlt';
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        margin: 0;
    }
    .form-check-label {
        font-weight: 200;
    }

    .form-check-input:checked {
        background-color: gold;
        border-color: #77321C;
    }    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .titulo {
        font-family: 'Lora';
        font-size: 24px;
        font-weight: bold;
    }
    .mb-4 {
        width: 70%;
    }
    .submit_button {
        background-color: #77321C;
        color: #F9B4A4;
        font-family: 'GoldplayAlt';
        width: 160px;
        border-radius: 40px;
        --bs-btn-font-size: 1.2rem;
        --bs-btn-font-style: normal;
        text-align: center;
    }
    // ESTRELAS 
    .gostei {
        display: flex;
        justify-content: space-between;
        font-family: 'GoldplayAlt';
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        margin-right: 10px;
    }
    .rating {
        display: flex;
    }
    .rating input{
        position : absolute;
        left     : -100vw;
    }
    .rating label{
        width      : 48px;
        height     : 48px;
        padding    : 48px 0 0;
        overflow   : hidden;
        background : url('@/assets/stars.svg') no-repeat top left;
    }
    .rating:not(:hover) input:indeterminate + label,
    .rating:not(:hover) input:checked ~ input + label,
    .rating input:hover ~ input + label{
        background-position : -48px 0;
    }       
    .rating:not(:hover) input:focus-visible + label{
        background-position : -96px 0;
    }   

    @media (max-width: 767px) {
        .mb-4 {
            width: 80%;
        }
    }


</style>