<template>
    <div id="footer" class="text-center">
        <span class="icons">
            <a href="https://www.instagram.com/dalenatortas/" style="text-decoration:none">
                <font-awesome-icon icon="fa-brands fa-square-instagram" size="2x" />
                <span class="icon-text">INSTAGRAM</span>
            </a>
        </span>
        <p>® 2023 • Dalena | Todos os direiros reservados.</p>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }

    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    .icon-text {
        font-family: 'GoldplayAlt';
        font-size: 18px;
        margin-left: 5px;
        margin-right: 30px;
        font-style: normal;
    }

    .icons{
        display: flex;
        align-items: center;
    }

    a {
        display: flex;
        align-items: center;
    }

    #footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 20px 20px;
        background-color:  #77321C;
        width: 100%;
    }
    
    p {
        font-family: 'Lora';
        font-size: 14px;
        color: #CE842D;
        margin: 2px;
    }
    a {
        color: #CE842D;
    }

    @media (max-width: 767px) {
        #footer {
            flex-direction: column;
            line-height: 20px;
        }

        .icons {
            margin-bottom: 6px;
        }

        p {
            font-size: 10px;
        }
    }
</style>