<template>
    <div class="lista">
        <img class="banner-detailimg2" :src="require(`@/assets/images/banner_detail2.webp`)" />
        <h1 class="title">{{title}}s</h1>
        <div class="produtos">
            <div class="spinner-grow" role="status" v-if="loading"></div>
            <div class="col-6 col-sm-4" v-for="produto in list" :key="produto.prod_id">
                <a v-bind:href="`/produto/${produto.prod_id}`">
                    <div class="card bg-transparen h-100">
                        <div class="foto">
                            <img v-bind:src="require(`@/assets/images/produtos/${tipo}_nb/${produto.prod_id}.webp`)" v-bind:alt="`${produto.name}`" class="card-img-top img-fluid">
                        </div>
                        <div class="card-body">
                            <p class="card-title">{{ produto.name }}</p>
                            <div class="stars" >
                                <div class="star-rating">
                                    <span v-for="star in 5" :key="star"><font-awesome-icon icon="fa-regular fa-star" /></span>
                                    <div class="star-rating__current" :style="{width: ((produto.rating/5)*100)+'%'}">
                                        <span v-for="star in 5" :key="star" ><font-awesome-icon icon="fa-solid fa-star"  id="solidStar"/></span>
                                    </div>
                                </div>
                                <span class="votes"><small>{{ produto.rating.toFixed(1) }}</small></span>
                            </div>
                            <p class="card-text">{{ limitText(produto.desc) }}</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from '@unhead/vue'

export default {
    name: "Produtos",
    setup() {
        useHead({
            title: 'Tortas e Bolos'
        })
    },
    data() {
        return {
            list: [],
            tipo: this.$route.params.tipo,
            title: '',
            loading: true
        }
    },
    created() {
        fetch(`https://6zk5h46ao2tkvb3emi4xosrcju0fvayv.lambda-url.us-east-2.on.aws/api/produtos/${this.$route.params.tipo}`)
        .then(async response => {
            const data = await response.json()

            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } 

            this.list = data;
            this.loading = false
        })
        .catch(error => {
            console.error('An error ocurred: ', error);
        })

        this.title = this.tipo.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                            return letter.toUpperCase();
                        });
    },
    methods: {
        limitText(desc) {
            if (desc.length > 20) {
                    desc = desc.substring(0, 58) + ' ...';
                }
            return desc 
        },
        login () {
            this.$gtag.event('login', { method: 'Google' })
        }
    }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }
    .spinner-grow {
        color: #77321C;
        width: 6rem;
        height: 6rem;
    }
    .banner-detailimg2 {
        display: block;
        width: 23%;
        position: absolute;
        top: 100px;
        left: 0;
        margin-left: 40px;
    }
    .title {
        margin: 60px 0 100px 0;
        text-align: center;
        font-family: 'Lora';
        color: #77321C;
        font-size: 32px;
        font-style: italic;
    }
    .produtos {
        margin: 0 30px 0 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }


    //   CARD 

    a {
        text-decoration: none;
    }
    .card {
        margin: 0 10px 0 10px;
        background-color: transparent;
        border: none;
    }
    .card-body {
        font-family: 'Lora';
        align-self: center;
        text-align: left;
        line-height: 1.8;
        padding-top: 8px;
        color: #77321C;
    }
    .card-title {
        font-size: 22px;
        font-weight: bold;
        margin: 0;
    }
    
    .foto {
        background-color: #EDE2D1;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .produtos>div{
        margin-bottom: 15px;
    }


    //   ESTRELAS 

    .star-rating {
        display: inline-block;
        color: #D0862E;
        position: relative;
        font-size: 10px;
        vertical-align: middle;
    }

    .star-rating .star-rating__current {
        position: absolute;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
    }

    .fa-star {
        margin-right: 3px;
    }
    .votes {
        font-family: 'Lora';
        font-size: 12px;
        color: #D0862E;
        margin-left: 2px;
    }


    //  MOBILE

    @media (max-width: 767px) {
        .banner-detailimg2 {
            display: none;
        }
        .title {
            margin: 20px 0 30px 0;
        }
        .produtos {
            margin: 0 8px 0 0;
        }
        .produtos>div{
            padding: 0 0 0 8px;
        }
        .card {
            margin: 0;
        }

        .card-body {
            padding: 0 0 0 5px;
        }

        .card-title {
            white-space: wrap;
            font-size: 16px;
        }
        .card-text {
            font-size: 12px;
            line-height: 1.3;
            margin-top: -3px;
        }

        .stars{
            white-space: nowrap;
            margin-top: -10px;
        }
    }
</style>