<template>
  <div class="home">
      <div class="banner">
        <div id="carouselControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="slide1">
                    <div class="col-md-4 col-12">
                      <div class="banner-text">
                        <p id="msg1">Delivery</p>
                        <p id="msg2">Sabor &<br>Tradição</p>
                        <p id="msg3">AO SEU ALCANCE</p>
                        <a type="button" class="btn btn-md" href="https://loja.dalena.com.br" target=”_blank” role="button" id="peca_agora">PEÇA AGORA!</a>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <img class="banner-img" :src="require(`@/assets/images/torta.webp`)" />
                    </div>
                  </div>
                </div>
            </div>
        </div> 
      </div>
      <div class="title">
        <h1>Produtos mais amados</h1>
      </div>
      <div class="top">
        <div class="spinner-grow" role="status" v-if="loading"></div>
        <div class="col-12 col-md-4" v-for="produto in list" :key="produto.prod_id">
          <a v-bind:href="`/produto/${produto.prod_id}`">
            <div class="card border-light bg-transparen h-100">
                <img v-bind:src="require(`@/assets/images/produtos/torta_nb/${produto.prod_id}.webp`)" class="card-img-top img-fluid">
                <div class="card-body">
                    <p class="card-text">{{ produto.name.toUpperCase() }}</p>
                    <div class="stars" >
                        <div class="star-rating">
                            <span v-for="star in 5" :key="star"><font-awesome-icon icon="fa-regular fa-star" /></span>
                            <div class="star-rating__current" :style="{width: ((produto.rating/5)*100)+'%'}">
                                <span v-for="star in 5" :key="star" ><font-awesome-icon icon="fa-solid fa-star"  id="solidStar"/></span>
                            </div>
                        </div>
                        <span class="votes"><small>{{ produto.rating.toFixed(1) }}</small></span>
                    </div>
                </div>
            </div> 
          </a>
        </div>
        <a type="button" class="btn btn-md" role="button" href="/cardapio" id="mais_cardapio">CONFIRA CARDÁPIO COMPLETO</a>
      </div>
      <div class="banner2">
        <img class="separator" id="banner1" :src="require(`@/assets/images/banner2.webp`)" />
        <img class="separator" id="banner2" :src="require(`@/assets/images/banner2_2.webp`)" />
      </div>
      <div class="title2">
        <h1>Conheça nosso cardápio</h1>
      </div>
      <div class="know-more">
        <div class="col-12 col-md-4">
            <a href="/cardapio/torta">
              <div class="card border-light bg-transparen h-100">
                  <img v-bind:src="require(`@/assets/images/maistorta.webp`)" class="card-img-top img-fluid" alt="Tortas"> 
                  <div class="card-body">
                    <h5 class="card-title">TORTAS</h5>
                  </div>
              </div>
            </a>
        </div>
        <div class="col-12 col-md-4 center_know">
          <a href="/cardapio/bolo">
            <div class="card border-light bg-transparen h-100">
                <img v-bind:src="require(`@/assets/images/maisbolo.webp`)" class="card-img-top img-fluid" alt="Bolos"> 
                <div class="card-body">
                  <h5 class="card-title">BOLOS</h5>
                </div>
            </div> 
          </a>
        </div>
      </div>
      <div class="to_top">
        <a href="#" role="button" class="btn btn-md"><font-awesome-icon icon="fa-solid fa-angle-up" size="3x" /></a>
      </div>
  </div>
</template>

<script>
import { useHead } from '@unhead/vue'

export default {
  name: 'Home',
  setup() {
    useHead({
      title: 'Home'
    })
  },
  data() {
        return {
            list: [],
            loading: true
        }
  },
  created() {
        fetch(`https://6zk5h46ao2tkvb3emi4xosrcju0fvayv.lambda-url.us-east-2.on.aws/api/produtos/top/get`)
        .then(async response => {
            const data = await response.json()

            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } 

            this.list = data;
            this.loading = false
        })
        .catch(error => {
            console.error('An error ocurred: ', error);
        })
    },
    methods: {
      login () {
        this.$gtag.event('login', { method: 'Google' })
      }
    }
}
</script>

<style lang="scss" scoped>

  // FONTES

  @font-face {
      font-family: 'AutumnChant';
      src: local("Autumn Chant"),
      url(@/fonts/Autumn-Chant.otf) format("truetype");
  }
  @font-face {
      font-family: 'GoldplayAlt';
      src: local("GoldplayAlt"),
      url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
  }
  @font-face {
      font-family: 'Lora';
      src: local("Lora"),
      url(@/fonts/Lora-Italic.ttf) format("truetype");
  }

  // PAGINA
  .home {
    background-color: white;
  }

    // BANNER E CAROUSEL
  .banner {
    background-image: url('@/assets/images/banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 580px;
    margin-bottom: 15px;
  }

  .carousel {
    width: 100%;
  }

  .carousel-control-prev-icon{
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CE842D' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  .carousel-control-next-icon {
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CE842D' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");  
  }

  .slide1,.slide2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-text {
    margin-left: 20px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .banner-img {
    width: 100%;
    padding: 15px;
  }

  #peca_agora {
    background-color: #F9B4A4;
    color: #77321C;
    font-family: 'Lora';
    width: 320px;
    margin-top: 18px;
    border-radius: 40px;
    --bs-btn-font-size: 1.2rem;
    --bs-btn-font-weight: bold;
  }

  #msg1 {
    color: #CE842D;
    font-family: 'AutumnChant';
    font-size: 45px;
    font-weight: bold;
  }

  #msg2 {
    color: #77321C;
    font-family: 'Lora';
    line-height: 65px;
    font-size: 80px;
  }

  #msg3 {
    color: #CE842D;
    font-family: 'GoldplayAlt';
    font-size: 38px;
    margin-top: -8px;
    font-style: normal;
  }

  // TITULOS MEIO DE PAGINA

  .title,.title2 {
    text-align: center;
  }

  h1 {
    font-family: 'Lora';
    color: #77321C;
    margin: 25px 0 25px 0;
    font-size: 32px;
    font-style: italic;
  }

  // MELHORES PRODUTOS
  .spinner-grow {
        color: #77321C;
        width: 6rem;
        height: 6rem;
    }
  .top {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 10px 40px 10px 40px;
  }

  .top>div {
    margin-bottom: 15px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  //   ESTRELAS 

  .star-rating {
    display: inline-block;
    color: #D0862E;
    position: relative;
    font-size: 10px;
    vertical-align: middle;
  }

  .star-rating .star-rating__current {
      position: absolute;
      top: 0;
      overflow: hidden;
      white-space: nowrap;
  }

  .fa-star {
      margin-right: 3px;
  }

  .votes {
      font-family: 'Lora';
      font-size: 12px;
      color: #D0862E;
      margin-left: 2px;
  }

  // CARD TORTAS

  .card {
    margin: 0 20px 0 20px;
    background-color: transparent;
    border: none;
  }

  .card-body {
    align-self: center;
    text-align: center;
    line-height: 1.1;
    padding-top: 8px;
    color: #77321C;
  }

  .card-text {
    font-size: 18px;
    font-family: 'Lora';
    font-weight: bold;
    margin: 0;
  }

  .card-title {
    font-size: 18px;
    font-family: 'Lora';
    font-weight: bold;
  }

  // BOTAO PARA CARDAPIO

  #mais_cardapio {
    background-color: #77321C;
    color: #F9B4A4;
    font-family: 'Lora';
    width: 320px;
    margin-top: 20px;
    border-radius: 40px;
    --bs-btn-font-size: 1.2rem;
    --bs-btn-font-style: normal;
    display: none;
    text-align: center;
  }
  
  .separator {
    width: 100%;
  }

  #banner1 {
    display: block;
  }
  
  #banner2 {
    display: none;
  }

  // CONHECA MAIS

  .know-more {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    padding: 0 80px 10px 80px;
  }

  .know-more>div>a>.card {
    padding-top: 10px;
  }

  .center_know{
    border-left: 1px solid rgba(119, 50, 28, 0.5);
  }

  .know-more>div {
    padding: 0 50px 0 50px;
  }

  // BOTAO PARA O TOPO

  .to_top {
    display: none;
  }

  // MOBILE

  @media (max-width: 767px) {

    // BANNER 

    .banner{
      background-image: url('@/assets/images/banner_mobile.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      flex-wrap: wrap-reverse;
    }

    .slide1,.slide2 {
      flex-wrap: wrap-reverse;
    }

    .banner-text {
      align-items: center;
    }

    .banner-img {
      padding: 15px 20px 15px 20px;
      margin-top: 30px;
    }

    #peca_agora {
      font-size: 16px;
      margin-bottom: 30px;
      width: 200px;
    }

    #msg1 {
      font-size: 30px;
    }

    #msg2 {
      line-height: 40px;
      font-size: 50px;
      margin-top: -8px;
    }

    #msg3 {
      font-size: 24px;
      margin-top: -12px;
    }

    h1 {
      margin: 10px 0 10px 0;
    }

    .title2>h1 {
      padding: 0 60px 0 60px;
    }

    // MELHORES PRODUTOS

    .top {
      padding: 10px 30px 10px 30px;
    }

    #mais_cardapio {
      display: block;
      font-size: 16px;
    }

    #banner1 {
      display: none;
    }
    
    #banner2 {
      display: block;
      margin-bottom: 20px;
    }

    // CONHECA CARDAPIO

    .know-more {
      padding: 10px 60px 10px 60px;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .center_know {
      border: none;
    }

    .know-more>div {
      padding: 0;
    }

    .to_top {
      display: flex;
      justify-content: flex-end;
      margin-top: -60px;
    }

    .fa-angle-up {
      color: #CE842D;
    }
  }
</style>
