<template>
  <div class="avaliacoes">
    <div class="banner">
      <img class="banner-detailimg1" :src="require(`@/assets/images/banner_detail1.webp`)" />
    </div>
    <div class="title">
        <h1>Avalie nossos produtos!</h1>
        <p>Sua opinião faz parte do nosso sucesso</p>
    </div>
    
    <div class="avalie">
      <input type="text" class="form-control" placeholder="Pesquisa" :value="name" @input="event => name = event.target.value">
      <table class="table table-striped table-hover">
        <tbody>
          <tr class="itens" v-for="produto in filteredList" :key="produto.prod_id">
            <td><img class="img-fluid imgTorta" v-bind:src="require(`@/assets//images/produtos/${produto.tipo}_thumb/${produto.prod_id}.webp`)" v-bind:alt="`${produto.name}`"></td>
            <td class="item_name">{{ produto.name }}</td>
            <td><a type="button" class="btn btn-md" v-bind:href="`/produto/${produto.prod_id}?rate=true`" target=”_blank” role="button" id="avalie">Avalie</a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="banner2">      
      <img class="banner-detailimg3" :src="require(`@/assets/images/banner_detail3.webp`)" />
    </div>
    <div class="to_top">
      <a href="#" role="button" class="btn btn-md"><font-awesome-icon icon="fa-solid fa-angle-up" size="3x" /></a>
    </div>
  </div>

</template>

<script>
import list from '@/data/lista.json';
import { useHead } from '@unhead/vue'

export default {
  name: 'Avalie',
  setup() {
    useHead({
      title: 'Avalie'
    })
  },
  data() {
      return {
        lista: list.list,
        name: ''
      }
  },
  computed: {
    filteredList: function() {
      return this.lista.filter(produto => (produto.name.toLowerCase().includes(this.name.toLowerCase())))

    }
  },
  methods: {
    login () {
      this.$gtag.event('login', { method: 'Google' })
    }
  }
}
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'AutumnChant';
        src: local("Autumn Chant"),
        url(@/fonts/Autumn-Chant.otf) format("truetype");
    }
    @font-face {
        font-family: 'GoldplayAlt';
        src: local("GoldplayAlt"),
        url(@/fonts/GoldplayAlt-Medium.ttf) format("truetype");
    }
    @font-face {
        font-family: 'Lora';
        src: local("Lora"),
        url(@/fonts/Lora-Italic.ttf) format("truetype");
    }

    // BANNER

  .banner-detailimg1 {
    width: 18%;
    position: absolute;
    top: 100px;
  }
  // title
  .title {
    text-align: center;
    margin: 30px 0 35px 0;
    line-height: 0.8;
  }

  h1 {
    font-family: 'Lora';
    color: #77321C;
    font-size: 30px;
    font-style: italic;
  }

  p {
    font-family: 'Lora';
    color: #77321C;
    font-size: 18px;
    font-style: italic;
  }

  // avalie
  .avalie {
    margin: 20px 400px 20px 400px;
    display: flex;
    flex-direction: column;
  }

  .avalie>input {
    width: 100%;
  }
  .table-striped>tbody>tr:nth-child(odd){
    background-color:#EDE2D1;
  }

  .itens {
    display: flex;
    align-items: center;
  }
  .itens>td {
    height: 65px;
    display: flex;
    align-items: center;
  }
  .item_name{
    font-family: 'Lora';
    color: #77321C!important;
    font-size: 22px;
    font-style: italic;
    width: 100%;
  }

  .imgTorta {
    height: 50px;
    width: auto;
    min-width: 50px;
  }
  

  #avalie {
    background-color: #F9B4A4;
    color: #77321C;
    font-family: 'Lora';
    width: 80px;
    border-radius: 40px;
    --bs-btn-font-size: 0.8rem;
    --bs-btn-font-weight: bold;
  }

  
  // banner baixo

  .banner-detailimg3 {
    height: 260px;
    margin-left: auto;
  }
  .to_top {
    display: none;
  }

  @media (max-width: 767px) {
    .banner-detailimg1 {
      top: 70px;
    }
    .avalie {
      margin: 20px 10px 20px 10px;
    }

    .to_top {
      display: flex;
      justify-content: flex-end;
      margin-top: -60px;
    }

    .fa-angle-up {
      color: #CE842D;
    }
  }
</style>
